import { themeBase } from './theme-base';

export const theme = {
  ...themeBase,

  delivery: {
    ...themeBase.delivery,
    icon: themeBase.delivery.icon,
    height: '2.15rem',
    width: '2.4rem',
  },

  pickup: {
    ...themeBase.pickup,
    icon: 'poppyBag',
    height: '2.15rem',
    width: '2.15rem',
  },
};
