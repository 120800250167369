import styled from 'styled-components';

import { brandFont } from 'styles/brand-font';

import {
  QRCodeContainer as QRCodeContainerBase,
  QRCodeSubtitle as QRCodeSubtitleBase,
  QRCodeTitle as QRCodeTitleBase,
  StyledQRCode as StyledQRCodeBase,
} from './loyalty-in-restaurant-redemption.styled.base';

export {
  Container,
  StyledRefreshIcon,
  SkeletonContainer,
  SkeletonBox,
  QRCodeWrapper,
  RefreshExpiredCode,
  RefreshExpiredCodeTitle,
  RefreshExpiredCodeSubtitle,
  StyledRefreshButton,
  GenerateNewCode,
  ShortCodeContainerV3,
  ShortCodeSubtitleV3,
  ShortCodeContentV3,
  SkeletonContainerV3,
  SkeletonBoxV3,
  BackButton,
  PageHeader,
  PageName,
} from './loyalty-in-restaurant-redemption.styled.base';

export const QRCodeContainer = styled(QRCodeContainerBase)`
  background-color: ${Styles.color.white};
  justify-content: center;
  margin: 0.5rem 0;
  border-radius: ${Styles.borderRadius};

  padding: 1.5rem;
  text-align: center;
  & > button {
    margin: 0;
  }
`;

export const QRCodeTitle = styled(QRCodeTitleBase)`
  font-size: ${brandFont.headerTwo};
  color: ${Styles.color.black};
`;

export const QRCodeSubtitle = styled(QRCodeSubtitleBase)`
  font-size: ${brandFont.copyTwo};
  color: ${Styles.color.black};
`;

export const StyledQRCode = styled(StyledQRCodeBase)`
  margin-block-end: 0;
  padding: 1rem;
  border: 1px solid ${Styles.color.grey.nine};
  border-radius: 10px;
`;
